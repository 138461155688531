
// Libraries
import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component({})
export default class LoadingSpinnerMolecule extends Vue {
  @Prop({ default: "default" }) variant: string;
  @Prop({ default: 0 }) timelimitMiliseconds: number;
  @Prop({ default: "p" }) failMessageTag: boolean;
  @Prop({
    default:
      "Inhalte konnten nicht geladen werden, bitte versuchen Sie es später noch einmal.",
  })
  loadingFailedMessage: string;

  loading: { status: boolean } = {
    status: true,
  };
  timer = null;

  mounted() {
    this.stopLoadingProccess();
  }

  stopLoadingProccess() {
    if (this.timelimitMiliseconds === 0) this.loading.status = true;
    this.timer = setTimeout(() => {
      this.loading.status = false;
    }, this.timelimitMiliseconds); // TODO: possible memory leak
  }

  beforeDestroy() {
    clearTimeout(this.timer);
  }
}
