

// Libraries
import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component({})
export default class AuthorImageMolecule extends Vue {
  @Prop({ default: require('~/assets/images/authors/placeholder.svg') }) src: string;
  @Prop({ default: 'Bild des Autors' }) alt: string;
  @Prop({ default: '80' }) width: string;
  @Prop({ default: '80' }) height: string;
  @Prop({ default: 'default' }) variant: string;
}

