
// Libraries
import { Vue, Component, Prop } from "nuxt-property-decorator";

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import LoadingSpinner from "~/components/molecules/display/LoadingSpinner.vue";
import AuthorImage from "~/components/molecules/embedding/AuthorImage.vue";
import { getLogoUrl } from "~/operations/shared";

@Component({
  components: {
    RouterLink,
    LoadingSpinner,
    AuthorImage,
  },
})
export default class BlogPostAuthorMolecule extends Vue {
  @Prop() postThumbnail: { small: string; medium: string; large: string };
  @Prop() postDate: { date: { dddd: string; dd: string; mmmm: string } };
  @Prop({ default: false }) showPriceDevelopmentIcon: boolean;
  @Prop({ default: "default" }) variant: string;
  @Prop({ default: "loading" }) loadingThumbnail: string; // loading | eager
  @Prop() alt: string;
  @Prop({
    type: Object,
    default() {
      return {
        simple_local_avatar: {
          full: require("~/assets/images/authors/placeholder.svg"),
        },
        name: "unknown author",
      };
    },
  })
  authorData: { simple_local_avatar: { full: string }; name: string };

  async mounted() {
    // await this.fetchPriceDevelopment(); // speculation: Data not needed. -> 20 times calles on news/index.vue
  }

  async fetchPriceDevelopment() {
    await this.$store.dispatch("schwarmometer/fetchPriceDevelopmentDirection");
  }

  get blogpostImageWebp() {
    return `${process.env.PARTNER_MEDIA_CDN}/img/blogposts/${this.postDate.date.dd}.webp`;
  }

  get blogpostImageJpg() {
    return `${process.env.PARTNER_MEDIA_CDN}/img/blogposts/${this.postDate.date.dd}.jpg`;
  }

  get logoUrl() {
    return getLogoUrl();
  }
}
